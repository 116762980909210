import React, { useState } from 'react'
import {
    Box,
    ColorGuide,
    Typography,
    ArrowBackwardIcon,
    Divider,
    ArrowForwardIcon,
    Pill,
    Switch,
} from 'components'
import { getAvailabilityForDate, useParams } from 'utils'
import ReactCalendar from 'react-calendar'
import { format, parse, isBefore, isPast, toDate } from 'date-fns'
import './calendar.css'
import firebase from 'utils/firebase'

export default function FreelancerDemoCalendarSection({
    freelancer,
    onEdit,
}) {
    const lastUpdatedDate = freelancer.lastUpdated
        ? format(freelancer.lastUpdated.toDate(), 'MMMM do, yyyy')
        : null

    const setUser = (data) =>
        firebase
            .firestore()
            .collection('users')
            .doc(freelancer.id)
            .set(data, { merge: true })

    return (
        <Box
            maxWidth={420}
            boxSizing="border-box"
            border="1px solid #DDD"
            borderRadius={10}
            py={3}
            px={3.5}
        >
            {onEdit && (
                <Box
                    display="flex"
                    alignItems="center"
                    padding={1}
                    mb={2.5}
                    flexVariant="row space-between"
                    style={freelancer.hidden ? { backgroundColor: 'red' } : { backgroundColor: 'green' }}
                >
                    <Typography color='secondary'>Profile is {!freelancer.hidden ? 'Visible' : 'Hidden'}</Typography>
                    <Switch variant="small" checked={!freelancer.hidden} onChange={e => setUser({ hidden: !e.target.checked })} />
                </Box>
            )}
            <Box flexVariant="space-between align-center">
                <Box display='flex' flexDirection='column'>
                    <Typography variant="h5" style={{ fontWeight: '600' }}>
                        Availability
                    </Typography>
                    {freelancer.employment && (
                        <Typography variant="body2">
                            Normally in office {freelancer.employment.vagueDays} days/week. {freelancer.employment.remoteStatus}
                        </Typography>
                    )}
                </Box>

                {onEdit && (
                    <Pill size="small" onClick={onEdit}>
                        Edit
                    </Pill>
                )}
            </Box>
            {/* Last updated may be worth implementing */}
            {/* {lastUpdatedDate && (
        <Typography variant="body2" style={{ marginTop: 4 }}>
          Last updated on {lastUpdatedDate}
        </Typography>
      )} */}

            <Divider />

            <SmallCalendar user={freelancer} />

            <Box maxWidth={400} mx="auto" my={3}>
                <ColorGuide />
            </Box>

        </Box>
    )
}

const SmallCalendar = ({ user, hideWeekends }) => {
    const { date: dateParam } = useParams()
    const startDate = parse(
        dateParam || format(new Date(), 'yyyy-MM-dd'),
        'yyyy-MM-dd',
        +new Date(),
    )
    const [activeDate] = useState(startDate)

    const getTileClassName = ({ date, view }) => {
        if (date.getDay() === 0 || date.getDay() === 6) {
            return 'react-calendar__tile--unavailable'
        }
        const scheduledClass = user.employment?.scheduledDays.includes(date.getDay()) ? 'react-calendar__tile-border' : ''
        switch (user.employment?.status) {
            case 'Full Time':
                return `react-calendar__tile--available ${scheduledClass}`
            case 'Contract':
                return `react-calendar__tile--available ${scheduledClass}`
            case 'Freelance':
                return `react-calendar__tile--maybe-available ${scheduledClass}`
            default:
                return `react-calendar__tile--unset ${scheduledClass}`
        }
    }

    const getTileContent = ({ date, view }) => {
        const today = user.employment?.scheduledDays.includes(date.getDay()) || false
        if (today) {
            return (
                <AvailabilityMessageIndicator
                    color={'gray'}
                    hideWeekends={true}
                />
            )
        }
    }

    return (
        <ReactCalendar
            maxDetail="month"
            minDetail="month"
            className="mini-calendar"
            formatShortWeekday={(_, date) => days[date.getDay()]}
            nextLabel={<ArrowForwardIcon style={{ fontSize: 20, fill: '#1d1d1d' }} />}
            prevLabel={
                <ArrowBackwardIcon style={{ fontSize: 20, fill: '#1d1d1d' }} />
            }
            tileClassName={getTileClassName}
            //   tileContent={getTileContent}
            defaultActiveStartDate={startDate}
            activeDate={activeDate}
        />
    )
}

const days = ['Sun', 'Mon', 'Tue', 'Wed', 'Thu', 'Fri', 'Sat']
const availabilityClasses = [
    'react-calendar__tile--unset',
    'react-calendar__tile--available',
    'react-calendar__tile--unavailable',
    'react-calendar__tile--maybe-available',
]

function AvailabilityMessageIndicator({ color }) {
    return (
        <Box
            bgcolor={color}
            style={{
                width: 4,
                height: 4,
                borderRadius: 20,
                position: 'absolute',
                bottom: 2,
            }}
        />
    )
}
