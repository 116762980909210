import React from 'react'
import { Box, Divider } from 'components'
import { FilterCheckbox } from './FilterCheckbox'
import { GENDER_TAGS_ENABLED, DIVERSITY_TAGS_ENABLED } from 'constants'
import { useDiversityTags, useGenderTags } from 'utils'
import { Chip } from '@material-ui/core'

export function DiversityFilter({ filterState: state, setFilterState: setState }) {
  const { gender_tags, diversity_tags } = state
  const diversityTags = useDiversityTags()
  const genderTags = useGenderTags()

  if (!diversityTags || !genderTags) return <></>

  const onDiversityTagChange = (tag) => {
    if (diversity_tags.includes(tag.name)) {
      setState({ ...state, diversity_tags: diversity_tags.filter((t) => t !== tag.name) })
    } else {
      setState({ ...state, diversity_tags: [...diversity_tags, tag.name] })
    }
  }
  const onGenderTagChange = (tag) => {
    if (gender_tags.includes(tag.name)) {
      setState({ ...state, gender_tags: gender_tags.filter((t) => t !== tag.name) })
    } else {
      setState({ ...state, gender_tags: [...gender_tags, tag.name] })
    }
  }
  return (
    <Box py={1.5} flex={1} flexVariant="space-between align-center" minHeight="fit-content">
      {GENDER_TAGS_ENABLED && (
        <Box display="flex" alignItems="center" flex={1} flexDirection="column">
          <Box
            display="flex"
            alignItems="center"
            flex={1}
            flexDirection="row"
            flexWrap="wrap"
            width="30dvw">
            {genderTags.map((tag) => (
              <Chip label={tag.name} key={tag.id} style={{ margin: 2 }} variant={gender_tags.includes(tag.name) ? 'default' : 'outlined'} color='primary'
                onClick={() => onGenderTagChange(tag)} />
            ))}
          </Box>
          <Box my={2} />
          <Box
            display="flex"
            alignItems="center"
            flex={1}
            flexDirection="row"
            flexWrap="wrap"
            width="30dvw">
            {diversityTags.map((tag) => (
              <Chip label={tag.name} key={tag.id} style={{ margin: 2 }} variant={diversity_tags.includes(tag.name) ? 'default' : 'outlined'} color='primary'
                onClick={() => onDiversityTagChange(tag)} />
            ))}
          </Box>
        </Box>
      )}
    </Box>
  )
}