import React, { useEffect } from 'react'
import { Divider, Frame, Typography, Button, Box } from 'components'
import { useContent, useHistory } from 'utils'
import firebase from 'utils/firebase'
import { Helmet } from 'react-helmet'
import { USE_DEMO_LANDER } from 'constants'

export const GenericStaticLander = (props) => {
  const history = useHistory()
  const { landing, logo } = useContent()

  useEffect(() => {
    firebase.analytics().logEvent('user_landing_page_visit')
  }, [])

  if (!landing) return null

  return (
    <Frame py={0} maxWidth={null} containerStyle={{ padding: 0, whiteSpace: 'pre-line' }}>

      <Helmet>
        <title>{landing.heading}</title>
      </Helmet>

      <Box display="flex" flexDirection="column" alignItems="center" px={4} style={{ maxWidth: 1000, margin: '0 auto' }}>
        <Box display="flex" flexDirection="column" alignItems="center" mt={4} mb={3}>
          {logo &&
            <img
              src={logo}
              alt="logo"
              style={{ maxWidth: '100%', height: 80 }} />
          }

          <Typography align="center" style={{ color: 'black', fontSize: 10 }}>
            {landing.taglineShort.toUpperCase()}
          </Typography>
        </Box>

        <Typography align="center" variant="h2" style={{ fontWeight: '800', fontSize: 48 }}>
          {landing.heading}
        </Typography>

        <Typography align="center" variant="h3" style={{ lineHeight: '1.1', fontWeight: '400' }}>
          {landing.tagline}
        </Typography>

        <Box display="flex" mt={2} flexDirection={{ xs: 'column', md: 'row' }} alignItems="center" style={{ gap: 10 }}>
          <Typography align="center" style={{ fontWeight: '600', color: 'black' }}>
            {landing.sponsor?.textValue}
          </Typography>

          {landing.sponsor &&
            <img
              src={landing.sponsor.imageValue[0].src}
              alt="logo"
              style={{ maxWidth: '100%', height: 50 }} />
          }
        </Box>
        {USE_DEMO_LANDER && (
          <Box display='flex' flexDirection='row' width='80%' mt={8}>
            <Divider width='30%' />
            <Typography align='center' style={{ width: '40%', justifySelf: 'center', alignSelf: 'center' }}>
              This is where we
            </Typography>
            <Divider width='30%' />
          </Box>
        )}
        <Box display="flex" flexDirection={{ xs: 'column', md: 'row' }} mt={USE_DEMO_LANDER ? 3 : 8} style={{ gap: 60 }}>
          {landing['use-cases'].map((useCase, i) =>
            <Typography key={i} align="center" style={{ lineHeight: '1.3' }}>
              {useCase.text}
            </Typography>
          )}
        </Box>
        {USE_DEMO_LANDER && (
          <Box width="100%" mt={1}><Divider /></Box>
        )}
        {USE_DEMO_LANDER && (
          <Box mt={8}>
            <Typography variant='h4'>Register Now</Typography>
          </Box>
        )}
        <Box display="flex" flexDirection={{ xs: 'column', md: 'row' }} flex={1} mt={4} style={{ gap: 20, width: '100%' }}>
          <Button
            color={landing['freelancer-signup-color'] || "primary"}
            variant="contained"
            onClick={() => {
              firebase.analytics().logEvent('user_freelancer_signup_click')
              history.push('/freelancer-signup')
            }}
            style={{ flex: 1 }}
          >
            {landing['freelancer-signup-label']}
          </Button>

          <Button
            variant="outlined"
            color={landing['subscriber-signup-color'] || "primary"}
            onClick={() => {
              firebase.analytics().logEvent('user_subscriber_signup_click')
              history.push('/signup')
            }}
            style={{ flex: 1 }}
          >
            {landing['subscriber-signup-label']}
          </Button>
        </Box>
      </Box>


      <Box display="flex" flexDirection="column" alignItems="center" px={4} style={{ maxWidth: 1200, margin: '40px auto' }}>
        <Box display="flex" alignItems="center" width="100%" style={{ gap: 50 }}>
          <Divider style={{ flex: 1, background: 'black' }} />
          <Typography align="center" variant="h4">
            {landing['member-logos'].textValue}
          </Typography>
          <Divider style={{ flex: 1, background: 'black' }} />
        </Box>

        {USE_DEMO_LANDER && (
          <Box mt={2} mb={2}>
            <Box>
              <Typography align='center' variant='subtitle2'>
                Logos of network agencies • Links to online courses • Professional development links
              </Typography>
            </Box>
            <Box>
              <Typography align='center' variant='subtitle2'>
                Big upcoming projects • Message from the CEO • Announcements / News
              </Typography>
            </Box>
          </Box>
        )}

        <div style={{ display: 'grid', gridTemplateColumns: 'repeat(8, minmax(0, 1fr))', alignItems: 'center' }}>
          {landing['member-logos'].imageValue.map((image, i) => (
            <a
              target="_blank"
              href={landing['member-logos'].textValues?.[i]?.text}
              rel="noreferrer"
              key={image.src}
            >
              <img
                src={image.src}
                alt="logo"
                style={{ maxWidth: '100%' }}
              />
            </a>
          ))}
        </div>

        {USE_DEMO_LANDER ? (
          <Divider />
        ) : (
          <Typography align="center" variant="h4">
            {landing['footer-cta']}
          </Typography>
        )}



      </Box>
      <Box mt={8} />

    </Frame>
  )
}

