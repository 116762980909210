import debounce from 'lodash/debounce'
import firebase from 'utils/firebase'
import xor from 'lodash/xor'
import Fuse from 'fuse.js'
import pick from 'lodash/pick'
import { getNotes, transformMarketFreelancers, updateNotes } from 'utils'
import { useEffect, useState, useCallback } from 'react'
import isAfter from 'date-fns/isAfter'
import uniq from 'lodash/uniq'

export * from './availability'
export * from './roles'
export * from './recommendations'
export * from './flags'
export * from './improve'

const confirmEmail = firebase.functions().httpsCallable('confirmEmail')

export const createUser = async ({ email, password, ...rest }) => {
  const lowercaseEmail = email.toLowerCase()
  try {
    if (window.Cypress) {
      return
    }

    await firebase
      .auth()
      .createUserWithEmailAndPassword(lowercaseEmail, password)

    confirmEmail({
      to: lowercaseEmail,
      firstName: rest.firstName,
      role: rest.role,
    })
    firebase.analytics().logEvent('signup')

    await firebase
      .firestore()
      .collection('users')
      .doc(firebase.auth().currentUser.uid)
      .set({
        createdAt: new Date(),
        email: lowercaseEmail,
        unsafeWeekends: true,
        ...rest,
      })
  } catch (e) {
    if (e.code === 'auth/email-already-in-use') {
      alert(
        'Hmm... this e-mail is already in use. You have an account, or at least started one. Try logging in instead.',
      )
    } else {
      alert('Sorry, there was an issue creating your account: ' + e.message)
    }
  }
}

export const createJobBoardPost = async (data, user) => {
  try {
    await firebase
      .firestore()
      .collection('users')
      .doc(firebase.auth().currentUser.uid)
      .collection('jobBoardPosts')
      .add({
        created: new Date(),
        ...data,
        company: data.company || user.company,
      })
  } catch (e) {
    window.alert(e)
  }
}

export const editJobBoardPost = async (data, user) => {
  try {
    await firebase
      .firestore()
      .collection('users')
      .doc(firebase.auth().currentUser.uid)
      .collection('jobBoardPosts')
      .doc(data.id)
      .set({
        ...data,
        company: user.company || null,
      })
  } catch (e) {
    window.alert(e)
  }
}

export const deleteJobBoardPost = async (id) => {
  try {
    await firebase
      .firestore()
      .collection('users')
      .doc(firebase.auth().currentUser.uid)
      .collection('jobBoardPosts')
      .doc(id)
      .delete()
  } catch (e) {
    window.alert(e)
  }
}

export const getUserJobBoardPosts = async () => {
  try {
    const posts = await firebase
      .firestore()
      .collection('users')
      .doc(firebase.auth().currentUser.uid)
      .collection('jobBoardPosts')
      .get()
    return posts.docs.map((doc) => ({ ...doc.data(), id: doc.id }))
  } catch (e) {
    window.alert(e)
  }
}

export const getActiveJobBoardPosts = async () => {
  try {
    const posts = await firebase
      .firestore()
      .collectionGroup('jobBoardPosts')
      .get()
    return posts.docs
      .map((doc) => ({ ...doc.data(), id: doc.id }))
      .filter(
        (post) =>
          post.endDate &&
          isAfter(post.endDate?.toDate() || new Date(), new Date()),
      )
  } catch (e) {
    window.alert(e)
  }
}

export const deleteUser = (password) => {
  return new Promise(async (resolve, reject) => {
    try {
      const cred = firebase.auth.EmailAuthProvider.credential(
        firebase.auth().currentUser.email,
        password,
      )
      await firebase.auth().currentUser.reauthenticateWithCredential(cred)
      await firebase.auth().currentUser.delete()
      localStorage.removeItem('has-session')
      resolve()
    } catch (e) {
      alert('Sorry, there was an issue deleting your account: ' + e.message)
    }
  })
}

export const updatePassword = async (oldPassword, newPassword) => {
  try {
    const cred = firebase.auth.EmailAuthProvider.credential(
      firebase.auth().currentUser.email,
      oldPassword,
    )
    await firebase.auth().currentUser.reauthenticateWithCredential(cred)
    await firebase.auth().currentUser.updatePassword(newPassword)
  } catch (e) {
    alert(
      'Sorry, there was an issue updating your password. Please try again later.',
    )
    console.error(e)
  }
}

export const signOut = async () => {
  try {
    await firebase.auth().signOut()
    localStorage.removeItem('has-session')
  } catch (e) {
    alert('Sorry, there was an issue signing out. Please try again later.')
    console.error(e)
  }
}

export const signIn = async (email, password) => {
  try {
    await firebase.auth().signInWithEmailAndPassword(email, password)
    firebase.analytics().logEvent('login')
  } catch (e) {
    alert('Your email or password is incorrect')
    console.error(e)
  }
}

export const forgotPassword = async (email) => {
  try {
    await firebase.auth().sendPasswordResetEmail(email)
    alert(
      `We've sent you an email to ${email} that will allow you to reset your password.`,
    )
  } catch (e) {
    alert('Sorry, there was an issue: ' + e.message)
    console.error(e)
  }
}

const _updateUser = (user, update) =>
  firebase
    .firestore()
    .collection('users')
    .doc(user.id)
    .set(update, { merge: true })

export const updateUser = debounce(_updateUser, 500, { leading: true })

export const formatPhoneNumber = (number) => {
  let phoneNumber = number.replace(/\D/g, '').slice(0, 10)
  const match = phoneNumber.match(/^(\d{1,3})(\d{0,3})(\d{0,4})$/)

  if (phoneNumber.length === 0) {
    return ''
  }

  if (match) {
    return `${match[1]}${match[2] ? '-' : ''}${match[2]}${match[3] ? '-' : ''}${match[3]
      }`
  }
}

export const toggleFavorite = (user, userToToggle) => {
  updateUser(user, {
    favorites: xor(user.favorites, [userToToggle.id]),
  })
}

export const toggleHidden = (user, userToToggle) => {
  updateUser(user, {
    hiddenUsers: xor(user.hiddenUsers, [userToToggle.id]),
  })
}

export const onClickUserWebsiteLink = (link) => {
  const url = !!link.match(/^https?:\/\//) ? link : `http://${link}`
  window.open(url)
}

export const onClickUserEmail = (user) => {
  window.open(`mailto:${user.email}`)
}

export const onCallUser = (user) => window.open(`tel:${user.phoneNumber}`)

export const onTextUser = (user) => window.open(`sms:${user.phoneNumber}`)

export const searchUsers = (
  query,
  users,
  keys = ['firstName', 'lastName'],
  callback = () => { },
) => {
  if (query !== '') {
    const fuse = new Fuse(
      users.map((u) => ({
        id: u.id,
        firstName: u.firstName.trim(),
        lastName: u.lastName.trim(),
      })),
      {
        keys,
        threshold: 0.2,
        distance: 0,
        minMatchCharLength: 2,
      },
    )

    const results = fuse.search(query)

    const resultUsers = results.map((r) =>
      users.find((u) => r.item.id === u.id),
    )
    callback(resultUsers, results)
    return resultUsers
  }
  return []
}

const authUserKeys = [
  'email',
  'emailVerified',
  'displayName',
  'isAnonymous',
  'metadata',
]

export const useFirebaseAuth = () => {
  const [authUser, setAuthUser] = useState(firebase.auth().currentUser)

  useEffect(() => {
    return firebase.auth().onAuthStateChanged((authUser) => {
      const user = authUser
        ? {
          id: authUser.uid,
          ...pick(authUser, authUserKeys),
        }
        : authUser

      if (user) {
        localStorage.setItem('has-session', '1')
        setAuthUser(user)
      } else {
        localStorage.removeItem('has-session')
      }
    })
  }, [])

  return [authUser, setAuthUser]
}

export const useFirebaseUser = (authUser) => {
  const [user, setUser] = useState(null)

  useEffect(() => {
    if (authUser) {
      return firebase
        .firestore()
        .collection('users')
        .doc(authUser.id)
        .onSnapshot((update) => {
          setUser(update.data())
        })
    }
  }, [authUser])

  let patchedUser

  if (authUser && user) {
    if (user.role === 'freelancer' || user.role === 'admin') {
      patchedUser = {
        unsafeWeekends: false,
        availability: {},
        roles: {},
        about: '',
        website: '',
        websites: [],
        ...user,
        id: authUser.id,
      }
    } else {
      patchedUser = {
        hiddenUsers: [],
        favorites: [],
        ...user,
        id: authUser.id,
      }
    }
  }

  return [patchedUser, setUser]
}

export const useFirebaseAggregates = (authUser, user) => {
  const [aggregates, setAggregates] = useState(null)

  const updateAggregates = useCallback(async () => {
    const freelancerDocs = await firebase
      .firestore()
      .collection('aggregates')
      .get()
    const marketDoc = await firebase
      .firestore()
      .collection('aggregates')
      .doc('markets')
      .get()
    const rolesDoc = await firebase
      .firestore()
      .collection('aggregates')
      .doc('roles')
      .get()
    const citiesDoc = await firebase.firestore().collection('cities').get()
    const cities = citiesDoc.docs.map((d) => ({ id: d.id, ...d.data() }))
    let freelancers = {}
    if (freelancerDocs.size) {
      freelancerDocs.forEach((doc) => {
        freelancers[doc.id] = doc.data()
      })
      freelancers = transformMarketFreelancers(freelancers)
    }

    let markets = null
    if (marketDoc.exists && rolesDoc.exists) {
      const roles = rolesDoc.data()
      markets = marketDoc.data()
      markets = Object.entries(markets).reduce(
        (markets, [marketId, market]) => {
          const city = cities.find((c) => c.id === market.cityId)
          const index = +(city?.index || 99)
          return {
            ...markets,
            [marketId]: { ...market, index, roles: roles[market.industryId] },
          }
        },
        {},
      )
    }
    setAggregates({ freelancers, markets, cities })
  }, [])

  useEffect(() => {
    if (!aggregates) {
      updateAggregates()
    }
  }, [user, aggregates, updateAggregates])

  const _aggregates = aggregates || {
    freelancers: {},
    markets: {},
  }

  return [_aggregates, updateAggregates]
}

export const freelancersAgg = firebase
  .functions()
  .httpsCallable('freelancersAgg')

export const jobBoardEmail = firebase
  .functions()
  .httpsCallable('handleNewJobBoardPosts')

export const createGroup = async (data, user) => {
  try {
    const groups = await getUserGroups()
    await firebase
      .firestore()
      .collection('users')
      .doc(firebase.auth().currentUser.uid)
      .collection('lists')
      .add({
        created: new Date(),
        name: '',
        index: groups.length,
        color: 'yellow',
        ...data,
        freelancers: [],
      })
  } catch (e) {
    window.alert(e)
  }
}

export const editGroup = async (data) => {
  try {
    await firebase
      .firestore()
      .collection('users')
      .doc(firebase.auth().currentUser.uid)
      .collection('lists')
      .doc(data.id)
      .set({
        ...data,
      })
  } catch (e) {
    window.alert(e)
  }
}

export const addFreelancerToGroup = async (group, freelancerId) => {
  try {
    const user = await getUser()
    if (group.id === 'favorites') {
      await toggleFavorite(user, { id: freelancerId })
      return
    }
    await firebase
      .firestore()
      .collection('users')
      .doc(firebase.auth().currentUser.uid)
      .collection('lists')
      .doc(group.id)
      .set(
        {
          ...group,
          freelancers: uniq([...(group.freelancers || []), freelancerId]),
        },
        { merge: true },
      )
  } catch (e) {
    window.alert(e)
  }
}

export const removeFreelancerFromGroup = async (group, freelancerId) => {
  try {
    const user = await getUser()
    if (group.id === 'favorites') {
      await toggleFavorite(user, { id: freelancerId })
      return
    }
    await firebase
      .firestore()
      .collection('users')
      .doc(firebase.auth().currentUser.uid)
      .collection('lists')
      .doc(group.id)
      .set(
        {
          ...group,
          freelancers: group.freelancers.filter((f) => f !== freelancerId),
        },
        { merge: true },
      )
  } catch (e) {
    window.alert(e)
  }
}

export const deleteGroup = async (id) => {
  try {
    await firebase
      .firestore()
      .collection('users')
      .doc(firebase.auth().currentUser.uid)
      .collection('lists')
      .doc(id)
      .delete()
  } catch (e) {
    window.alert(e)
  }
}

export const getUser = async () => {
  try {
    const user = await firebase
      .firestore()
      .collection('users')
      .doc(firebase.auth().currentUser.uid)
      .get()

    return { ...user.data(), id: firebase.auth().currentUser.uid }
  } catch (e) {
    window.alert(e)
  }
}

export const getUserGroups = async () => {
  try {
    const user = await getUser()
    const posts = await firebase
      .firestore()
      .collection('users')
      .doc(firebase.auth().currentUser.uid)
      .collection('lists')
      .get()
    const userGroups = posts.docs.map((doc) => ({ ...doc.data(), id: doc.id }))
    return [
      {
        id: 'favorites',
        name: 'Favorites',
        color: 'yellow',
        index: -9999,
        freelancers: user.favorites,
      },
      ...userGroups,
    ].sort((a, b) => a.index - b.index)
  } catch (e) {
    window.alert(e)
  }
}

export const useUserNotes = (freelancerId) => {
  const [notes, setNotes] = useState('')
  const [note, setNote] = useState('')
  const userId = firebase.auth().currentUser.uid

  useEffect(() => {
    if (userId) {
      getNotes(userId).then((r) => {
        setNotes(r)
        if (freelancerId) setNote(r[freelancerId] || '')
      })
    }
  }, [userId, freelancerId])

  const hasChanges = freelancerId && notes[freelancerId] !== note

  const onSave = () => {
    updateNotes(userId, freelancerId, note).then(() =>
      setNotes((n) => ({ ...n, [freelancerId]: note })),
    )
  }

  return { notes, note, hasChanges, setNote, onSave }
}
