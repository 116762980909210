import { Box, Typography, Divider, Pill, } from 'components'

export default function FreelancerDiversityTagSection({ freelancer, onEdit }) {
    return (
        <Box flex={1} pt={3.5} px={3.5}>
            <Box flex={1} flexVariant="row space-between" mb={2.5}>
                <Typography variant="h4" style={{ fontWeight: '600' }}>
                    Diversity Notes
                </Typography>
                {onEdit && (
                    <Box>
                        <Pill size="small" onClick={onEdit} style={{ margin: '0px 8px' }}>
                            Manage Tags
                        </Pill>
                    </Box>
                )}

            </Box>
            <Box my={2} />
            {freelancer.genderTags && (
                <Box flexVariant="row align-center" flexWrap="wrap">
                    {freelancer.genderTags.map((genderTag, i) => (
                        <DiversityPill
                            key={genderTag + i}
                            diversityTag={genderTag}
                        />
                    ))}
                </Box>
            )}
            {freelancer.genderTags && freelancer.diversityTags && (
                <Box my={3} />
            )}
            {freelancer.diversityTags && (
                <Box flexVariant="row align-center" flexWrap="wrap">
                    {freelancer.diversityTags.map((diversityTag, i) => (
                        <DiversityPill
                            key={diversityTag + i}
                            diversityTag={diversityTag}
                        />
                    ))}
                </Box>
            )}
            <Divider />
        </Box>
    )
}

export const DiversityPill = ({ diversityTag }) => (
    <Pill
        key={diversityTag}
        //   bgcolor={role.links && role.links[skill] ? '#000000' : 'white'}
        style={{ marginRight: 8, marginBottom: 8 }}
    //   textStyle={{ color: role.links && role.links[skill] ? '#ffffff' : '#111' }}
    >
        {diversityTag}
    </Pill>
)