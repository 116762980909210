import React from 'react'
import { Box, Typography, Pill, Divider } from 'components'
import { List, ListItem, ListItemText, Link } from '@material-ui/core'

export default function FreelancerCreditSection({ freelancer, onEdit }) {
  if ((freelancer.credits || []).length > 0) {
    return (
      <Box flex={1} pt={3.5} px={3.5}>
        <Box flex={1} flexVariant="row space-between" mb={2.5}>
          <Typography variant="h4" style={{ fontWeight: '600' }}>
            Credits
          </Typography>

          {onEdit && (
            <Pill size="small" onClick={onEdit}>
              Edit Credits
            </Pill>
          )}
        </Box>
        <List>
          {freelancer.credits.map(({ projectName, role, projectUrl, year }, index) => {
            if (!projectUrl.startsWith('https://') && !projectUrl.startsWith('http://')) {
              projectUrl = `https://${projectUrl}`
            }
            return (
              <ListItem key={index}>
                <ListItemText
                  primary={projectUrl ? (<Link href={projectUrl} target="_blank" rel="noopener noreferrer">{projectName}</Link>) : projectName}
                  secondary={`${role} - ${year}`}
                />
                {/* <ListItemText secondary={year} /> */}
              </ListItem>
            )
          })}
        </List>
        <Divider />
      </Box>
    )
  }
  return (
    (onEdit ? (
      <Box flex={1} pt={3.5} px={3.5}>
        <Box flex={1} flexVariant="row space-between" mb={2.5}>
          <Typography variant="h4" style={{ fontWeight: '600' }}>
            Credits
          </Typography>

          <Pill size="small" onClick={onEdit}>
            Add Credits
          </Pill>
        </Box>
      </Box>
    ): <></> )
  )
}
