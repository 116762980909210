import React, { useEffect, useState } from 'react'
import { CardList, Box, ProfileCard, Typography } from 'components'
import GroupDialog from '../ListDialog'
import { getUserGroups, useUserNotes } from 'utils'

const FreelancerList = ({
  onGroup,
  allowHide = true,
  roleLabel,
  date,
  user,
  users = [],
  noUsersText = 'No users match the current criteria',
}) => {
  const [selectedUser, setSelectedUser] = useState(null)
  const [groups, setGroups] = useState([])
  if (typeof onGroup === 'undefined') {
    onGroup = setSelectedUser
  }

  const { notes } = useUserNotes()

  useEffect(() => {
    getUserGroups().then(setGroups)
  }, [selectedUser])

  const sortByGroups = (a, b) => {
    const aIndex = groups.findIndex((group) =>
      group.freelancers?.includes(a.id),
    )
    const bIndex = groups.findIndex((group) =>
      group.freelancers?.includes(b.id),
    )

    if (aIndex === bIndex) return 0
    if (aIndex !== -1 && bIndex === -1) return -1
    if (bIndex !== -1 && aIndex === -1) return 1

    return aIndex - bIndex
  }

  const cards = [
    ...users
      .sort(sortByStatus)
      .sort(sortByGroups)
      .map((foundUser) => (
        <ProfileCard
          key={foundUser.id}
          date={date}
          user={user}
          profileUser={foundUser}
          onGroup={onGroup ? () => onGroup(foundUser) : null}
          allowHide={allowHide}
          hasNote={notes[foundUser.id]}
          subscriberGroups={groups.filter((l) =>
            l.freelancers?.some((id) => id === foundUser.id),
          )}
          role={roleLabel}
        />
      )),
  ]

  return (
    <Box className="freelancer-list" flex={1} mb={0.5}>
      {users.length > 0 ? (
        <CardList cards={cards} />
      ) : (
        <Box display="flex" alignItems="center">
          <Typography>{noUsersText}</Typography>
        </Box>
      )}
      <GroupDialog
        user={selectedUser}
        onClose={() => setSelectedUser(null)}
        open={!!selectedUser}
      />
    </Box>
  )
}
export default FreelancerList

const sortByStatus = (a, b) => {
  const availA = a.latestAvail
  const availB = b.latestAvail
  if (availA?.status === 0 || availA?.status === 2) {
    return 1
  }
  if (availB?.status === 0 || availB?.status === 2) {
    return -1
  }
  return availA?.status - availB?.status
}
