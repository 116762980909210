import React, { useState, useRef } from 'react'
import {
  Button,
  TextField,
  Divider,
  Heading,
  Typography,
  Frame,
} from 'components'
import { createUser, getFormValues, formatPhoneNumber, useContent } from 'utils'
import { Box } from '@material-ui/core'
import firebase from 'utils/firebase'
import { SUBSCRIPTIONS_ENABLED, CUSTOM_STATIC_LANDER, ONBOARD_WALKTHROUGH_URL, DISABLE_ONBOARD_MESSAGE, TUTORIAL_ENABLED, PRONOUNS_ENABLED } from 'constants'

const FreelancerCreateForm = () => {
  const [phoneNumber, setPhoneNumber] = useState('')
  const [stepIndex, setStepIndex] = useState(DISABLE_ONBOARD_MESSAGE ? 1 : 0)
  const videoRef = useRef(null)
  const { onboarding } = useContent()

  if (!onboarding) {
    return null
  }

  if (stepIndex === 0) {
    return (
      <Frame maxWidth="xs">
        <Heading >
          Employment status check
        </Heading>

        <Typography style={{ textAlign: 'center' }}>
          This Job Portal is for people who are actively looking for work and regularly taking on new projects.
          <br />
          {!CUSTOM_STATIC_LANDER ? (
            <Typography style={{ textAlign: 'center' }}>
              <br />
              This Portal is not for people with full time jobs who are not open to new work.
              <br />
            </Typography>
          ) : null}

          <br />
          Keep this in mind before you build a profile.
          <br />
          Thanks!
        </Typography>

        <Divider />

        <Box mx="auto" my={3} maxWidth={280}>
          <Button variant="contained" type="submit" style={{ width: '100%' }} onClick={() => setStepIndex(1)}>
            Begin
          </Button>
        </Box>
      </Frame >
    )
  }

  if (stepIndex === 1 && TUTORIAL_ENABLED) {
    return (
      <Frame maxWidth="xs">
        <Box mx="auto" maxWidth={430}>
          <Heading>
            Optional:
            Watch a walk-through
            of the onboarding process.</Heading>
          <Box display={'flex'} justifyContent={'center'}>
            {/* embed here */}
            <video ref={videoRef} style={{ maxWidth: '50dvw'}} height='auto' controls >
              <source type="video/mp4" src={ONBOARD_WALKTHROUGH_URL} />
              Your browser does not support the video tag.
            </video>
          </Box>
        </Box>
        <Box mx="auto" my={3} maxWidth={280} display='flex' flexDirection={'column'}>
          <Box width={'100%'}>
          <Button variant="contained" type="submit" style={{ width: '100%' }} onClick={() => videoRef.current.play()}>
            Watch Video
          </Button>
          </Box>
          <Box my={2} width={'100%'}>
          <Button variant="contained" type="submit" style={{ width: '100%' }} onClick={() => setStepIndex(2)}>
            Begin Onboarding
          </Button>
          </Box>
        </Box>
      </Frame>
    )
  }

  return (
    <Frame maxWidth="xs">
      <Heading subheading={onboarding['contact-subheading'] || SUBSCRIPTIONS_ENABLED ? "It's always free for freelancers" : ''}>
        {onboarding['contact-heading'] || <>Welcome aboard!<br />Let's start with your basic contact info.</>}
      </Heading>

      <form
        onSubmit={async (event) => {
          event.preventDefault()
          firebase.analytics().logEvent('user_freelancer_details_submit_click')
          await createUser({ ...getFormValues(event), role: 'freelancer' })
        }}
      >
        <Box display="flex">
          <TextField required name="firstName" label="First Name" />

          <Box mx={1} />

          <TextField required name="lastName" label="Last Name" />

          {PRONOUNS_ENABLED ? <><Box mx={1} /><TextField name="pronouns" label="Pronouns" /></> : null} 
        </Box>

        <TextField
          required
          name="website"
          label="LinkedIn"
          placeholder=""
        />
        <TextField required name="email" label="Email Address" />
        <TextField required name="password" type="password" label="Password" />
        <TextField
          name="phoneNumber"
          label="Phone Number"
          placeholder="Optional"
          value={phoneNumber}
          onChange={(e) => {
            const phoneNumber = formatPhoneNumber(e.target.value)
            setPhoneNumber(phoneNumber)
          }}
        />

        <Box mx="auto" maxWidth={280}>
          <Button type="submit" my={2}>
            Next
          </Button>
        </Box>
      </form>

      <Box mx="auto" mb={3} maxWidth={280}>
        <Typography variant="body2">
          By signing up for an account, you agree to the{' '}
          <Typography component="a" href="/terms">
            Terms of service
          </Typography>
          .
        </Typography>
      </Box>

      <Divider />

      <Box mx="auto" my={3} maxWidth={280}>
        <Typography variant="body2">
          Already have an account?{' '}
          <Typography component="a" href="/login">
            Sign in
          </Typography>
        </Typography>
      </Box>
    </Frame>
  )
}

export default FreelancerCreateForm
