import React, { useState, useEffect } from 'react'
import {
  Frame,
  FreelancerProfileRecommendations,
  FreelancerProfileContactSection,
  FreelancerExpertiseSection,
  FreelancerEquipmentSection,
  Box,
  FreelancerProfileCalendarSection,
  FreelancerCreditsSection,
  FreelancerWorkStatusSection,
  FreelancerCommitmentSection,
  FreelancerProfileWLCalendarSection,
  FreelancerDiversitySection,
  FreelancerDemoCalendarSection,
} from 'components'
import { getUserRecommendations, useHistory } from 'utils'
import FreelancerEditProfile from './FreelancerEditProfile'
import {
  COMMITMENT_STATUS,
  ONTARIO_WORKING_STATUS,
  DIVERSITY_TAGS_ENABLED,
  GENDER_TAGS_ENABLED,
  DISABLE_CRRAL_CALENDAR,
  USE_DEMO_LANDER,
} from 'constants'

const FreelancerProfile = ({ user, setSnackMessage }) => {
  const [recommendations, setRecommendations] = useState([])
  const [editMode, setEditMode] = useState(false)
  const history = useHistory()


  useEffect(() => {
    if (!user) return
    getUserRecommendations(user).then((recommendations) => {
      setRecommendations(Object.values(recommendations))
    })
  }, [user])

  if (editMode) {
    return (
      <Frame maxWidth="lg" user={user}>
        <FreelancerEditProfile
          user={user}
          setSnackMessage={setSnackMessage}
          onSubmit={() => setEditMode(false)}
        />
      </Frame>
    )
  }

  return (
    <Frame py={2.5} maxWidth="lg" user={user}>
      <Box display="flex" flexDirection={{ xs: 'column', md: 'row' }}>
        <Box flex={1} mr={2.5}>
          <FreelancerProfileContactSection
            onEdit={() => setEditMode(true)}
            freelancer={user}
            history={history}
          />
          {DIVERSITY_TAGS_ENABLED && (
            <FreelancerDiversitySection
              freelancer={user}
              onEdit={() => {
                if (GENDER_TAGS_ENABLED) {
                  history.push('/gendertags')
                  return
                }
                history.push('/diversitytags')
              }}
            />
          )}
          <FreelancerExpertiseSection
            freelancer={user}
            onEdit={() => history.push('/roles')}
          />
          <FreelancerCreditsSection
            freelancer={user}
            onEdit={() => history.push('/credits')}
          />
          <FreelancerEquipmentSection
            freelancer={user}
            onEdit={() => history.push('/equipment')}
          />
          <FreelancerProfileRecommendations
            onEdit={() => history.push('/recommendations/request')}
            freelancer={{ ...user, recommendations }}
          />
        </Box>
        <Box display='flex' flexDirection='column'>
          {COMMITMENT_STATUS &&
            <FreelancerCommitmentSection
              freelancer={user}
              onEdit={() => history.push('/availability')} />
          }
          {!DISABLE_CRRAL_CALENDAR && (
            <FreelancerProfileCalendarSection
              freelancer={user}
              onEdit={() => history.push('/month')}
            />
          )}
          {USE_DEMO_LANDER && (
            <FreelancerDemoCalendarSection freelancer={user} onEdit={() => history.push('/employmentstatus')} />
          )}
          {ONTARIO_WORKING_STATUS ? (
            <FreelancerWorkStatusSection
              freelancer={user}
              onEdit={() => history.push('/workstatus')}
            />
          ) : null}
          {COMMITMENT_STATUS ? (
            <FreelancerProfileWLCalendarSection
              freelancer={user}
              onEdit={() => history.push('/availability')}
            />
          ) : null}
        </Box>
      </Box>
    </Frame>
  )
}

export default FreelancerProfile
