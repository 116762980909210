import React, { useCallback, useEffect, useState } from 'react'
import {
  Button,
  TextField,
  Heading,
  FreelancerList,
  Box,
  SelectField,
  MenuItem,
} from 'components'
import DateFnsUtils from '@date-io/date-fns'
import { MuiPickersUtilsProvider } from '@material-ui/pickers'
import {
  createGroup,
  deleteGroup,
  editGroup,
  getUserById,
  getUserGroups,
  removeFreelancerFromGroup,
} from 'utils'
import { GROUP_COLORS } from 'constants.js'
import { ProfileIcons } from 'components/freelancer/ProfileCard'

const Form = ({ user, history, defaultValues = { name: '', color: '' } }) => {
  const [unchanged, setUnchanged] = useState(true)
  const [formState, _setFormState] = useState(defaultValues)
  const setFormState = (update) => {
    _setFormState(update)
    setUnchanged(false)
  }

  const onSubmit = async (event) => {
    event.preventDefault()
    if (!formState.name || !formState.color) return
    if (formState.id) {
      await editGroup(formState, user)
    } else {
      await createGroup(formState, user)
    }
    history.push('/groups')
  }

  const onDelete = () => {
    const confirm = window.confirm(
      'Are you sure you want to delete this group?',
    )
    if (confirm) {
      deleteGroup(formState.id).then(() => history.push('/groups'))
    }
  }

  // TODO: turn on message feature
  // const onMessage = () => {
  //   history.push(`/groups/message/${formState.id}`)
  // }

  return (
    <MuiPickersUtilsProvider utils={DateFnsUtils}>
      <Box display="flex" alignItems="center" justifyContent="center">
        {formState.color && <ProfileIcons groups={[formState]} />}
        <Box mr={1} />
        <Heading>
          {formState.name ? `${formState.name}` : 'Create New Group'}
        </Heading>
      </Box>
      {formState.id !== 'favorites' && (
        <form onSubmit={onSubmit}>
          <Box display="flex">
            <Box flex={1} mr={2}>
              <TextField
                required
                value={formState.name}
                onChange={(e) =>
                  setFormState((fs) => ({ ...fs, name: e.target.value }))
                }
                name="name"
                placeholder="My Project"
                label="Group Name *"
              />
            </Box>

            <Box flex={1}>
              <SelectField
                required
                flex={1}
                name="color"
                value={formState.color}
                onChange={(e) =>
                  setFormState((fs) => ({ ...fs, color: e.target.value }))
                }
                label="Color"
                choices={Object.keys(GROUP_COLORS)}
                renderChoice={(color) => (
                  <MenuItem key={color} value={color}>
                    <Box display="flex" alignItems="center">
                      <ProfileIcons groups={[{ id: color, color }]} />
                      <Box mr={1} />
                      {color.toUpperCase()[0] + color.slice(1)}
                    </Box>
                  </MenuItem>
                )}
              />
            </Box>
          </Box>
          <Box>
            <Button
              disabled={unchanged}
              variant="contained"
              color="primary"
              type="submit"
              style={{ flex: 1 }}
            >
              Save
            </Button>
            {formState.id && formState !== 'favorites' && (
              <Button onClick={onDelete}>Delete</Button>
            )}
            {/* {formState.id && formState !== 'favorites' && (
              <Button onClick={onMessage}>Message</Button>
            )} */}
          </Box>
        </form>
      )}

      <FreelancerGroupDetails user={user} group={formState} />
    </MuiPickersUtilsProvider>
  )
}

export default Form

const FreelancerGroupDetails = ({ user, group }) => {
  const [users, setUsers] = useState([])
  const [loading, setLoading] = useState(true)
  const refresh = useCallback(() => {
    getUserGroups().then((groups) => {
      const newGroup = groups.find(({ id }) => id === group.id)
      if (newGroup?.freelancers)
        Promise.all(newGroup.freelancers.map((id) => getUserById(id))).then(
          (users) => {
            setUsers(users.map(transformFreelancer))
            setLoading(false)
          },
        )
    })
  }, [group.id])
  useEffect(() => {
    refresh()
  }, [refresh])

  if (loading) return null
  return (
    <Box mt={4} width="100%">
      <FreelancerList
        allowHide={false}
        onGroup={(freelancer) => {
          const confirmed = window.confirm(
            `Are you sure you want to remove ${freelancer.firstName} from ${
              group?.name || 'Favorites'
            }?`,
          )
          if (confirmed)
            removeFreelancerFromGroup(group, freelancer.id).then(refresh)
        }}
        noUsersText="Add some freelancers to this group to get started"
        user={user}
        users={users}
      />
    </Box>
  )
}

export const getFreelancerGroupsWithUsers = () =>
  getUserGroups().then((groups) =>
    Promise.all(
      groups.map((group) =>
        Promise.all(group.freelancers.map((id) => getUserById(id))).then(
          (users) => ({
            ...group,
            freelancers: users,
          }),
        ),
      ),
    ),
  )

const transformFreelancer = (user) => {
  const roles = Array.isArray(user.roles)
    ? user.roles
    : Object.values(user.roles || {})

  return {
    ...user,
    experienceByRole: roles.reduce(
      (o, r) => ({ ...o, [r.label]: r.experience }),
      {},
    ),
    titles: roles.map((r) => r.label),
  }
}
